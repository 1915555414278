export const statusOptions = [
  {
    key: -1,
    display_name: "草稿",
  },
  {
    key: 0,
    display_name: "正在运行",
  },
  {
    key: 1,
    display_name: "完成",
  },
  {
    key: 2,
    display_name: "未知",
  },
  {
    key: 3,
    display_name: "没有通过",
  },
  {
    key: 4,
    display_name: "驳回",
  },
]
